body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background: #000;
  overflow: hidden;
  height: 100%;
}

::selection {
  color: #ffffff;
  background: #32689b;
}

::-moz-selection { 
  color: #ffffff; 
  background: #32689b; 
}

.app {
  position: relative;
  width: 100%;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.4);
}

.content {
  z-index: 2;
  color: white;
  padding: 2rem;
}

.header {
  font-size: 3.5rem;
  margin: 0;
  color: white;
}

.highlight {
  color: #6599ce;
}

.tagline {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  opacity: 0.8;
}

.prompt-container {
  margin: 2rem 0;
}

.modern-box {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 500px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  padding-top: 1rem;
  padding-bottom: 1rem;

  border-radius: 10px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.5);
  font-size: 1.1rem;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 30px;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.contact-form {
  display: flex;
  flex-direction: column;
  /* Stack items vertically */
  gap: 1rem;
  /* Add spacing between input and button */
  align-items: center;
  /* Center align items */
  justify-content: center;
}


.contact-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

/* Position the send icon inside the prompt box */
.send-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-size: 1.2rem;
  pointer-events: none;
  /* Make it inactive */
  opacity: 0.8;
}

.text-box {
  margin-right: 3rem;
  margin-left: 1rem;
  text-align: left;
  /* Align text to the left */
}

.custom-input {
  background: transparent !important;
  border: 2px solid #6599ce;
  color: white;
  text-align: center;

  padding: 0.3rem 1rem;
  border-radius: 10px;
  width: 300px;
  max-width: 90%;
  font-size: 1rem;
  outline: none;
  transition: all 0.3s ease;
}

.custom-input:hover {
  border-color: #84b8ec;
}

.custom-input:focus {
  background: transparent !important;
  border-color: #84b8ec;
  box-shadow: 0 0 5px rgba(101, 153, 206, 0.5);
}

.custom-input::placeholder {
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}

.custom-input::placeholder {
  color: rgba(255, 255, 255, 0.6); /* Lighter text color */
  transition: all 0.3s ease; /* Smooth transition for interactions */
}

.custom-input:focus::placeholder {
  color: rgba(255, 255, 255, 0.3); /* Fade placeholder on focus */
}


.custom-ant-button {
  background-color: transparent;
  color: #6599ce;
  border: 2px solid #6599ce;
  margin-top: 10px;
  padding: 1rem 1.5rem;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.3s ease;
  cursor: pointer;
}

.custom-ant-button:hover {
  background-color: #6599ce56!important;
  border-color: #6599ce;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.custom-ant-button:active {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 600px) {
  .header {
    font-size: 2.8rem;
  }

  .modern-box {
    font-size: 1rem;
  }
}


.btn-hover {
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 20px;
  height: 55px;
  text-align:center;
  border: none;
  background-size: 300% 100%;

  border-radius: 50px;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover.color-1 {
  background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}



#particle-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure it stays in the background */
  background: #000; /* Optional fallback background */
}
